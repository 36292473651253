export const categoriesColl = reactive([
    { 
        _id: "Happy Birthday",
        label: "Happy Birthday",
        topcat: "greetings",
        image: "/images/gallery-wrapper/birthday.jpg",
    },
    { 
        _id: "Happy Baptism Day",
        label: "Happy Baptism Day",
        topcat: "greetings",
        image: "/images/gallery-wrapper/baptism.jpg"
    },
    { 
        _id: "Bible inspirations",
        label: "Inspirations",
        topcat: "thematic",
        image: "/images/gallery-wrapper/inspirations.jpg"
    },
    { 
        _id: "Good morning",
        label: "Good morning",
        topcat: "thematic",
        image: "/images/gallery-wrapper/inspirations.jpg"
    },
    { 
        _id: "Good evening",
        label: "Good evening",
        topcat: "thematic",
        image: "/images/gallery-wrapper/inspirations.jpg"
    },
    { 
        _id: "Good night",
        label: "Good night",
        topcat: "thematic",
        image: "/images/gallery-wrapper/inspirations.jpg"
    },
    { 
        _id: "With the birth of a daughter",
        label: "With the birth of a daughter",
        topcat: "greetings",
        image: "/images/gallery-wrapper/daughter.jpg"
    },
    { 
        _id: "With the birth of a son",
        label: "With the birth of a son",
        topcat: "greetings",
        image: "/images/gallery-wrapper/son.jpg"
    },
    { 
        _id: "Happy New Year",
        label: "Happy New Year",
        topcat: "greetings",
        image: "/images/gallery-wrapper/new-year.jpg"
    },
    { 
        _id: "To the best grandmother",
        label: "To the best grandmother",
        topcat: "greetings",
        image: "/images/gallery-wrapper/grandmother.jpg"
    },
    { 
        _id: "The best mom",
        label: "The best mom",
        topcat: "greetings",
        image: "/images/gallery-wrapper/mom.jpg"
    },
    { 
        _id: "The best father",
        label: "The best father",
        topcat: "greetings",
        image: "/images/gallery-wrapper/father.jpg"
    },
    { 
        _id: "The best grandfather",
        label: "The best grandfather",
        topcat: "greetings",
        image: "/images/gallery-wrapper/grandfather.jpg"
    },
    { 
        _id: "Wedding",
        label: "Wedding",
        topcat: "greetings",
        image: "/images/gallery-wrapper/wedding.jpg"
    },
    { 
        _id: "Psalms",
        label: "Psalms",
        topcat: "bible",
        image: "/images/gallery-wrapper/psalms.jpg"
    },
    { 
        _id: "Saturday",
        label: "Saturday",
        topcat: "greetings",
        image: "/images/gallery-wrapper/saturday.jpg"
    },
    { 
        _id: "Paul\'s message",
        label: "Paul's message",
        topcat: "bible",
        image: "/images/gallery-wrapper/pavel.jpg"
    },
    { 
        _id: "Jacob, Peter, Ivan",
        label: "Jacob, Peter, Ivan",
        topcat: "bible",
        image: "/images/gallery-wrapper/yacow_petro_ivan.jpg"
    },
    { 
        _id: "Gospels",
        label: "Gospels",
        topcat: "bible",
        image: "/images/gallery-wrapper/psalms.jpg"
    },
    { 
        _id: "Pentateuch",
        label: "Pentateuch",
        topcat: "bible",
        image: "/images/gallery-wrapper/psalms.jpg"
    },
        { 
        _id: "Proverbs, Ecclesiastes",
        label: "Proverbs, Ecclesiastes",
        topcat: "bible",
        image: "/images/gallery-wrapper/ecclesiastes.jpg"
    },
        { 
        _id: "Great prophets",
        label: "Great prophets",
        topcat: "bible",
        image: "/images/gallery-wrapper/psalms.jpg"
    },
    //     { 
    //     _id: "Isaiah",
    //     label: "Isaiah",
    //     topcat: "bible",
    //     image: "/images/gallery-wrapper/psalms.jpg"
    // },
    //     { 
    //     _id: "Jeremiah",
    //     label: "Jeremiah",
    //     topcat: "bible",
    //     image: "/images/gallery-wrapper/psalms.jpg"
    // },
    //     { 
    //     _id: "Ezekiel",
    //     label: "Ezekiel",
    //     topcat: "bible",
    //     image: "/images/gallery-wrapper/psalms.jpg"
    // },
        { 
        _id: "Minor prophets",
        label: "Minor prophets",
        topcat: "bible",
        image: "/images/gallery-wrapper/psalms.jpg"
    },
    //     { 
    //     _id: "Daniel",
    //     label: "Daniel",
    //     topcat: "bible",
    //     image: "/images/gallery-wrapper/psalms.jpg"
    // },
        { 
        _id: "Revelation",
        label: "Revelation",
        topcat: "bible",
        image: "/images/gallery-wrapper/psalms.jpg"
    },
        { 
        _id: "Acts of the Apostles",
        label: "Acts of the Apostles",
        topcat: "bible",
        image: "/images/gallery-wrapper/psalms.jpg"
    }
    

]);

export const topcatColl = reactive([
    {
        _id: "bible",
        label: "Biblical",
    },
    {
        _id: "greetings",
        label: "Greetings",
    },
    {
        _id: "thematic",
        label: "Thematic",
    }
]);

function findCategoryById(categoryId: string) {
    return categoriesColl.find((c) => c._id == categoryId);
}

export function useCategories() {
    return {
        categoriesColl,
        topcatColl,
        findCategoryById,
    }
}